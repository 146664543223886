import usePermissions from 'hooks/usePermissions';
import React, { ReactNode, useMemo, useState } from 'react';
import { IComponent } from 'types/api-interfaces';
import { dummyComponent } from 'utils/dummyObjects';

export interface IComponentEditorContext {
  component: IComponent;
  /** Should be true when all meshes have been fully loaded in the scene instance */
  isSceneLoaded: boolean;
  setIsSceneLoaded: (value: boolean) => void;
  /** Keeps track of which tree node is selected, so it is remembered when you navigate away from the Editor tab */
  selectedKeys: React.Key[];
  setSelectedKeys: (value: React.Key[]) => void;
  /** The asset ID of the script file currently used as the component's behavior script */
  behaviorGuid: string | undefined;
  setBehaviorGuid: (guid: string) => void;
  canModify: boolean;
}

export const ComponentEditorContext = React.createContext<IComponentEditorContext>({
  component: dummyComponent,
  isSceneLoaded: false,
  setIsSceneLoaded: () => {},
  selectedKeys: [1],
  setSelectedKeys: () => {},
  behaviorGuid: '',
  setBehaviorGuid: () => {},
  canModify: false,
});

type ComponentEditorProviderProps = {
  children: ReactNode;
  component: IComponent;
};

function ComponentEditorProvider({ children, component }: ComponentEditorProviderProps) {
  const [isSceneLoaded, setIsSceneLoaded] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([1]);
  const [behaviorGuid, setBehaviorGuid] = useState<string>();
  const { canModify } = usePermissions(component);

  const providerValue = useMemo(
    () => ({
      component,
      isSceneLoaded,
      setIsSceneLoaded,
      selectedKeys,
      setSelectedKeys,
      behaviorGuid,
      setBehaviorGuid,
      canModify,
    }),
    [isSceneLoaded, selectedKeys, component, behaviorGuid, canModify],
  );
  return (
    <ComponentEditorContext.Provider value={providerValue}>
      {children}
    </ComponentEditorContext.Provider>
  );
}

export default ComponentEditorProvider;
